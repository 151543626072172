@import '~ng-pick-datetime/assets/style/picker.min.css';
$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/scss/font-awesome.scss';
@import './variables.scss';
@import 'src/assets/css/fontello';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

/*Global styles that override the component styles*/
@font-face {
    font-family: 'Gill Sans';
    src: url('./assets/font/Gill\ Sans\ Medium.otf') format('opentype');
}
* {
    box-sizing: inherit;
    font-family: 'Gill Sans';
    font-weight: 400;
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    html {
        font-size: 15px;
    }
    th {
        font-size: 16px;
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    html {
        font-size: 16px;
    }
    th {
        font-size: 17px;
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    html {
        font-size: 17px;
    }
}

body {
    margin: 0;
    background: white;
    font-size: inherit;
    line-height: 1.5;
    color: inherit;
    font-weight: 100 !important;
}

.splash {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    background-color: #ededee;
}
input,
button,
select,
textarea {
    font-family: inherit;
}

.main-content {
    height: 100%;
}

.mat-button {
    font-size: 1rem;
}
.mat-error ul {
    padding: 0px;
    padding-top: 5px;
    margin-left: 15px;
    margin-bottom: 0px;
}

.mat-form-field-underline {
    display: none;
}
.mat-vertical-content {
    padding: 24px !important;
}
.th {
    display: flex;
    justify-content: center;
}

.mat-slider-horizontal {
    min-width: 85px !important;
}

/* mat searchbar css overides */
.mat-autocomplete-panel.mat-autocomplete-visible {
    position: relative;
    left: -10px;
    top: 10px;
}
.mat-optgroup-label {
    color: $primary-color;
    font-weight: bolder;
    font-size: 18px;
}
/* end of mat searchbar css overides */

h4 {
    font-weight: 500;
    margin: 0;
}

p {
    margin: 0;
}

.ui-menu .ui-menuitem-parent,
.ui-menu .ui-menuitem {
    margin: 2.125em 0;
}

.ui-menuitem .ui-widget .ui-corner-all {
    margin: 1.125em 0;
}

@media (min-width: 992px) {
    .col-mat-10[_ngcontent-c0] {
        width: 80%;
    }
}

.mat-tab-link-container {
    background-color: transparent !important;
}
// cossgrid-secondary-nav .ui-menubar {
//   height: 100px;
// }

// cossgrid-secondary-nav .ui-menuitem .ui-widget .ui-corner-all {
//   margin: 1.125em 0;
// }

// cossgrid-secondary-nav .ui-menu .ui-menuitem-parent,
// .ui-menu .ui-menuitem {
//   margin: 2.125em 0;
// }

// ng-select>div>div.multiple {
//   display: inline;
//   padding: 0 10px;
// }

.clearfix {
    margin-top: 10px;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    cursor: pointer;
}

/* ========>>> material design overrides<<<============*/

/* ========>>> material Table Module<<<============*/

.mat-header-cell {
    font-size: 16px;
    font-weight: 600;
}
.mat-cell i {
    font-size: 1.5em;
    margin: 10px;
}

/* ========>>> End of material Table Module<<<============*/

.mat-radio-button:focus {
    text-decoration: none;
    outline: 0;
}
.mat-radio-button {
    margin-right: 60px !important;
}
.mat-form-field-label {
    font-weight: 500;
}

.mt .mat-toolbar .mat-toolbar-row {
    height: 48px !important;
}
.mat-toolbar {
    height: 40px !important;
    min-height: 40px !important;
}
.mat-toolbar-row {
    height: 40px !important;
}

.wrapper {
    padding: 20px 20px 10px 20px;
}
@media only screen and (max-width: 768px) {
    .wrapper {
        padding: 10px 20px 10px 20px;
    }
}
@media only screen and (max-width: 500px) {
    .wrapper {
        padding: 0px;
    }
}
.mat-dialog-container {
    padding: 0px !important;
    transform: none !important;
}
.mat-form-field-infix {
    padding-top: 0px !important;
    border-top: 0px !important;
}
cossgrid-ipsec-policy .ui-steps .ui-steps-item {
    width: 25%;
}

cossgrid-ipsec-policy .ui-steps.steps-custom {
    margin-bottom: 30px;
}

cossgrid-ipsec-policy .ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
    height: 10px;
    padding: 0 1em;
}

cossgrid-ipsec-policy .ui-steps.steps-custom .ui-steps-item .ui-steps-number {
    background-color: $primary-color;
    color: #ffffff;
    display: inline-block;
    width: 36px;
    border-radius: 50%;
    margin-top: -14px;
    margin-bottom: 10px;
}

cossgrid-ipsec-policy .ui-steps.steps-custom .ui-steps-item .ui-steps-title {
    color: #555555;
}
.mat-tab-nav-bar.mat-background-primary .mat-tab-link {
    color: #424242 !important;
}
/*.mat-tab-nav-bar{
  border-radius: 20px;
}*/
.mat-tab-nav-bar .mat-tab-links {
    //padding-left: 10px;
    border-radius: 0.28571429rem !important;
}

.mat-tab-nav-bar.mat-background-primary .mat-tab-links {
    /*  background-color: rgb(238, 238, 238);*/
    background-color: #fff;
    border-radius: 0.28571429rem !important;
}
.mat-form-field-underline {
    height: 2px !important;
}

.mat-tab-link {
    width: 160px;
    border-radius: 0.28571429rem !important;
    height: 38px !important;
    opacity: 1 !important;
    padding: 0 12px !important;
    font-size: 1rem;
    // font-family: 'Open Sans', sans-serif;
    min-width: 90px !important;
    font-weight: bold;
    color: #424242 !important;
}
.mat-ink-bar {
    height: 5px !important;
    background-color: $primary-color !important;
}

.ui-rowgroup-header-name {
    width: 96%;
}

.mat-multiselect .mat-multiselect-container {
    border-bottom: 0px !important;
}
.mat-card {
    box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5) !important;
    border-radius: 5px !important;
    /*padding: 10px !important;*/
}

/********************MAT SELECT OVERIIDES***************** */
.mat-select-underline {
    background-color: transparent;
}

mat-select {
    width: 100% !important;
    height: 100%;
    max-height: 25px;
    padding: 2px;
    border: 1px solid #ced4da;
    background-color: white;
    border-radius: 2px;
}
.mat-select-arrow {
    border-left: 3px solid transparent !important ;
    border-right: 4px solid transparent !important;
}
.mat-option {
    font-size: 12px !important ;
}
.mat-select-value span {
    padding-left: 2px !important;
    position: relative;
    bottom: 1px;
}
/********************END OF MAT SELECT OVERIIDES***************** */
.mat-toolbar.mat-primary {
    /* background: #00acc1;
  color: white;*/
    background: #fff;
    color: gray;
    box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5) !important;
    font-weight: bold;
    font-size: 20px;
    width: 98%;
    margin: auto;
    margin-bottom: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-radius: 5px;
}
.mat-tab-nav-bar {
    width: max-content;
    //margin:auto;
    margin-top: -10px;
    border-bottom: 1px solid #ccc !important;
}
.mat-tab-header {
    background: #fff;
}
.mat-tab-label-container {
    background: #f8f7fc;
    opacity: 1 !important;
}
.mat-select-underline {
    height: 0 !important;
}
.mat-select-trigger {
    line-height: normal !important;
}
.mat-select-placeholder {
    /* padding:0px 15px !important;
  color:#000;*/
    font-size: 12px;
}
.deviceDropdown .mat-select-placeholder {
    color: #000;
}
.mat-expansion-panel-body {
    padding: 0px !important;
}
.mat-select-value-text {
    padding: 0px 15px !important;
    font-size: 12px;
}
.mat-chip:not(.mat-basic-chip) {
    border-radius: 6px !important;
}
.mat-chip-list-wrapper {
    padding-top: 6px;
}
.mat-expansion-panel {
    box-shadow: none !important;
}

/* material snack overirde */
.mat-snack-bar-container {
    max-width: 100% !important;
}
/* end of material snack overide */
.form-group {
    margin: 0 !important;
}

.addBtn,
.editBtn {
    background-color: lightsteelblue !important ;
    color: black !important;
}
.footerDiv {
    display: flex;
    justify-content: center;
    padding-top: 16px;
    justify-content: flex-end;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1;
}
.cdk-overlay-dark-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
}
mat-toolbar {
    height: 60px !important;
    font-size: 18px !important;
}

/*loading round*/

.loaderRound {
    border: 5px solid #d8d8d9;
    border-radius: 50%;
    border-top: 5px solid $primary-light;
    width: 25px;
    height: 25px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-left: 45%;
    margin-bottom: 10px;
}
/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* end of loading round */

/* animated loading*/
.loader {
    text-align: center;
    height: 75px;
}
.loader span {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    margin: 50px auto;
    background: black;
    border-radius: 50px;
    animation: loader 0.9s infinite alternate;
}
.loader span:nth-of-type(2) {
    animation-delay: 0.3s;
}
.loader span:nth-of-type(3) {
    animation-delay: 0.6s;
}
@keyframes loader {
    0% {
        width: 10px;
        height: 10px;
        opacity: 0.9;
        transform: translateY(0);
    }
    100% {
        width: 24px;
        height: 24px;
        opacity: 0.1;
        transform: translateY(-20px);
    }
}
/*end of animated loading*/
select,
option {
    cursor: pointer;
}
.graphcard {
    padding: 5px !important;
    margin-bottom: 10px;
}
.formerror {
    color: red;
}

.required:after {
    content: ' *';
    color: red;
}

.inputError {
    outline: none;
    border: 1px solid;
    border-color: #ff7043;
    border-radius: 4px;
    box-shadow: 0 0 10px #ff7043;
    /*0 0 3.5px 1px red
*/
}
.card {
    border: 0px;
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    border: 0px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1) !important;
}
.form-control-underline {
    border: none;
    border-bottom: 2px solid #ccc;
    max-width: 4rem;
}

.form-control:disabled {
    opacity: 0.5 !important;
    background-color: #e9ecef !important;
}
.mask {
    max-width: 3rem;
}
.slash {
    line-height: 35px;
    font-size: 25px;
    color: #ccc;
}
.hint {
    color: #626262;
}
.materialAddBtn {
    width: 35px;
    height: 35px;
    padding: 6px 11px;
    font-size: 18px;
    font-weight: bolder;

    /* line-height: 1.33; */
    border-radius: 50px;
    background: lightsteelblue;
    border: 0;
    cursor: pointer;
    box-shadow: 2px 0px 17px -3px;
}
.materialDeleteBtn {
    background: salmon;
    border-radius: 25px;
    cursor: pointer;
    font-size: 18px;
    padding: 0px;
}
cosgrid-overlay-loader {
    margin: auto;
}
button:focus {
    outline: none !important;
}
.dialog-content {
    overflow: scroll;
    padding: 15px;
    max-height: 550px;
}
.datatable-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0px 1.5em !important;
}
.datatable-footer button {
    margin-left: 10px;
}

.datatable-card {
    padding: 0px;
}
.data-table > thead > tr > th {
    font-size: 16px;
    font-weight: normal;
}
.data-table > tbody > tr > td {
    font-size: 14px;
}

.data-table > thead {
    color: #424242;
}
.pagination-box button {
    height: 20px !important;
}
.pagination-controllers select {
    height: 20px !important;
}

.data-table-row {
    user-select: unset !important;
    -webkit-user-select: unset !important;
}
.ngx-ip-container {
    margin: 0px !important;
}
.ngx-ip-theme-material input {
    height: 22px !important;
}
.ngx-ip-theme-material.ngx-ip-focused .ngx-ip-table-cell.ngx-ip-error:not(.ngx-ip-disabled) {
    box-shadow: 0 2px 0 0 #f44336 !important;
}
.ngx-ip-theme-material.ngx-ip-focused .ngx-ip-table-cell:not(.ngx-ip-disabled) {
    box-shadow: 0 2px 0 0 #26a69a !important;
}
.ngx-ip-theme-material .ngx-ip-table-cell {
    border-bottom: 0px !important;
}
.ngx-ip-theme-material.ngx-ip-container {
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
}

.modal-body {
    overflow-y: scroll;
    max-height: 75vh;
    max-width: 550px;
    padding-left: 30px !important;
    padding-right: 30px !important;
    transition: height 0.5s;
}

.form-control {
    background-color: #fff !important;
}
.form-group {
    margin-bottom: 1rem !important;
}
.table th {
    vertical-align: middle;
}
/*datatable title*/
.title {
    color: #424242;
}
.mat-chip:not(.mat-basic-chip) {
    /* background-color: #efefef !important; */
    padding: 5px 12px !important;
    margin: 0px 0px 5px 8px !important;
}

/*font awesome*/

.fa-pencil-square-o {
    color: #eea236;
}
.fa-cog {
    cursor: pointer;
}
.data-table i {
    cursor: pointer;
    margin-right: 5px;
    font-size: 20px !important;
}
.data-table .fa-pencil-square-o {
    margin: 0px 0px 0px 0px;
}
.data-table-wrapper {
    margin: 10px 10px 10px 10px;
}
/*end font awesome*/

/*bootstrap 4.1.1*/
.dropdown-item {
    width: auto !important;
}

.DropdownAnimate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -webkit-opacity: 1;
    }
    0% {
        -webkit-transform: translateY(1rem);
        -webkit-opacity: 0;
    }
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
}

.btn-outline-info:hover {
    background: $primary-color !important;
    color: #fff !important;
}

.btn-outline-info {
    color: #fff !important;
    background: $primary-color !important;
    min-width: 60px;
}
.btn-outline-danger {
    min-width: 60px;
}
.modal-title,
label {
    font-weight: bold;
    color: #000;
}
.modal-title {
    padding-left: 15px;
}

.form-control:focus {
    box-shadow: 0 0 2px $primary-color;
}

.w-80 {
    width: 80% !important;
}
/* for line breaks in tooltip */
.tooltip-inner {
    /* white-space:pre-wrap; */
    max-width: 300px;
    text-align: left !important;
}

/* Bootstrap button override */

.btn {
    border-radius: 20px;
    min-width: 65px !important ;
}

/******end of bootstrap 4.1.1**********888*/

/******sweet alert*************/

.swal2-popup .swal2-styled {
    padding: 6px 1em !important;
}

/*sweet alert*/

/********** Input slider *************/
input[type='range'] {
    height: 38px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
}
input[type='range']:focus {
    outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000;
    background: #636363;
    border-radius: 5px;
    border: 1px solid #000000;
}
input[type='range']::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #fff;
    height: 25px;
    width: 15px;
    border-radius: 5px;
    background: #32a2d9;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -13px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
    background: #636363;
}
input[type='range']::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000;
    background: #636363;
    border-radius: 5px;
    border: 1px solid #000000;
}
input[type='range']::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #fff;
    height: 25px;
    width: 15px;
    border-radius: 5px;
    background: #32a2d9;
    cursor: pointer;
}
input[type='range']::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
input[type='range']::-ms-fill-lower {
    background: #636363;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000;
}
input[type='range']::-ms-fill-upper {
    background: #636363;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000;
}
input[type='range']::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #fff;
    height: 25px;
    width: 15px;
    border-radius: 5px;
    background: #32a2d9;
    cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
    background: #636363;
}
input[type='range']:focus::-ms-fill-upper {
    background: #636363;
}
/************ END OF Input slider **************/

/**** SECONDARY NAV *******/
/** Shared Status Component (for Monitor) **/

.spacer {
    flex: 1 1 auto;
}
.cosgrid.menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 1rem 0em;
    // font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    background: #ffffff;
    font-weight: normal;
    //border: 1px solid rgba(34, 36, 38, 0.15);
    -webkit-box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
    box-shadow: 0px 2px 4px 0 rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    max-width: 100%;
    overflow: auto;
    //min-height: 2.85714286em;
}

.cosgrid.menu:after {
    content: '';
    display: block;
    height: 0px;
    clear: both;
    visibility: hidden;
}

.cosgrid.menu:first-child {
    margin-top: 0rem;
    width: max-content;
}

.cosgrid.menu:last-child {
    margin-bottom: 0rem;
}

.cosgrid.pointing.menu .active.item:after {
    visibility: visible;
}

.cosgrid.pointing.menu .active.item .menu .active.item:after {
    display: none;
}

.cosgrid.pointing.menu .active.item:hover:after {
    background-color: #f2f2f2;
}

.cosgrid.pointing.menu .active.item:after {
    background-color: #f2f2f2;
}

.cosgrid.pointing.menu .active.item:hover:after {
    background-color: #f2f2f2;
}

.cosgrid.menu .item {
    width: 100px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    line-height: 1;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: none;
    padding: 0.92857143em 1.14285714em;
    text-transform: none;
    color: rgba(0, 0, 0, 0.87);
    font-weight: normal;
    -webkit-transition: background 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: background 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease;
}

.cosgrid.menu > .item:first-child {
    border-radius: 0.28571429rem 0px 0px 0.28571429rem;
}

.cosgrid.menu .active.item {
    font-weight: 600;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 4px solid $primary-color;
    color: $primary-color;
}

.cosgrid.menu .active.item:hover,
.cosgrid.vertical.menu .active.item:hover .item:hover {
    background-color: rgba(0, 0, 0, 0.05);
    //color: rgba(0, 0, 0, 0.95);
}

.cosgrid.menu .item:before {
    position: absolute;
    content: '';
    top: 0%;
    right: 0px;
    height: 100%;
    width: 1px;
    background: rgba(34, 36, 38, 0.1);
}

/****END OF  SECONDARY NAV *******/

/****CONFIGURE HEADER****/
.configure-header {
    padding: 5px 5px 5px 0px;
    background: slategray;
    color: #fff;
}
.configure-header .fa-question-circle-o {
    font-size: 1.5em;
}

.configure-header label {
    color: #fff;
}
/****CONFIGURE HEADER****/

/* CUSTOM css FOR SNACKBAR */
.customSnackClass {
    background-color: $warning-color;
    color: black;
    width: 100px;
    height: 30px;
}

.dialogBackdrop {
    opacity: 0;
}

/* LOADING  */

.lds-ellipsis {
    display: inline-block;
    position: relative;
    top: -15px;
    width: 64px;
    height: 24px;
}
.lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: black;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}
.clickable {
    cursor: pointer;
}
/* for that white color navbar, previously margin bottom was given to main router outlet but for some reason onboarding module is colliding with the margin  */
.toolbarSwitcher {
    margin-top: 5px;
    box-shadow: 0px 4px 10px 3px rgba(204, 197, 185, 0.5);
}
/* Toggle css overides */
ng-switchery {
    height: 20px;
}

.switchery {
    background-color: #bfbfbf !important ;
}
input:checked + .switchery-sm {
    box-shadow: $primary-color 0 0 0 12px inset !important;
}
.switchery.switchery-sm {
    margin-top: 0;
    height: 16px !important ;
    width: 32px !important;
}
input:checked + .switchery-sm > small {
    height: 12px !important;
    width: 12px !important;
    left: 19px !important;
    top: 2px !important;
}
.switchery > small {
    top: 2px !important;
    height: 12px !important;
    width: 12px !important;
}
/* table common styles */
table {
    font-weight: 400;
}
table mat-icon {
    padding-left: 5px;
}
th {
    font-weight: 400;
}

td {
    padding: 10px;
    font-size: 15px;
}

/* radio button overides */
.radio input {
    margin-right: 10px;
}

/* select inside mat-expansion-panel override */

mat-expansion-panel select {
    height: 18.3px !important;
}

.disable-select {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

/*  semantic colors */

.up-light {
    color: lawngreen;
}
.up-dark {
    color: green;
}

.down {
    color: red;
}

/* sort Icons */

.arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
}

/* Geo Location Autocomplete CSS Overides */
#search_places {
    height: 25px;
    font-size: 14px;
}
.search-default-icon {
    height: 12px !important;
    width: 12px !important;
}
.custom-autocomplete__dropdown {
    top: 27px !important;
}
.custom-autocomplete__dropdown li {
    font-size: 12px !important;
}
.tableHeading {
    margin-bottom: 20px;
}
.tableHeading h3 {
    font-size: 20px;
    font-weight: 600;
    padding-left: 10px;
    color: #525252;
}

.tableCurvedBtn {
    width: max-content;
    height: 30px;
    background-color: transparent;
    border: 1px solid;
    border-radius: 20px;
    color: $primary-light;
    background-color: #f1faff;
    font-weight: 400;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
    margin: 0px 5px 0px 5px;
    min-width: 70px;
}

.tableCurvedBtn:hover {
    background-color: rgb(220, 239, 250);
}

/* COSGRID TABLE CSS CLASS */

.cosgridTable {
    margin-top: 20px;
    margin-bottom: 20px;
}

.cosgridTable table {
    width: 100%;
    font-size: 14px !important ;
    border-radius: 10px 10px 10px 10px;
    background-color: white;
    box-shadow: 0px 4px 10px 3px rgba(204, 197, 185, 0.5);
}
/*.cosgridTable thead{
  background-color: #E8E8E8;
}*/
.cosgridTable th {
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-weight: 800;
}
.cosgridTable tbody tr {
    border-bottom: 1px solid #e8e8e8;
}
.cosgridTable tbody tr:hover {
    position: relative;
    box-shadow: 0px 0px 9px 1px rgba(204, 197, 185, 0.5);
    /* animating box shadow  */
    transition: box-shadow 50ms, font-weight 50ms;
}

.cosgridTable table th:first-child {
    border-radius: 10px 0 0 10px;
    padding-left: 20px;
}

.cosgridTable table th:last-child {
    border-radius: 0 10px 10px 0;
    padding-right: 20px;
}
.commercialDate .mat-form-field-flex {
    display: flex;
    align-items: unset;
}

cosgrid-inventory cosgrid-table-actions .fa-trash,
cosgrid-qos-class cosgrid-table-actions .fa-trash {
    display: none !important ;
}

cossgrid-vpn-setup .noDataContainer span {
    margin-top: 65px;
}

config-multi .mat-form-field-wrapper {
    padding-bottom: 0px !important;
}

.mat-dialog-container {
    overflow: unset !important ;
}
.removeEdit cosgrid-table-actions .fa-pencil-square-o {
    display: none !important;
}

/** Shawdow Enlarge Animation **/

.shawdowAnimation:hover {
    transform: scale(1.02);
    transition: transform 1000ms;
}

/** Text Property**/

.uppercaseText {
    text-transform: uppercase;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.02);
}

.custom-select {
    width: max-content;
    height: 20px;
}

.license-header {
    display: flex;
    margin-bottom: 10px;
    height: 30px;
}
.license-header button {
    margin-left: 20px;
}

.material-icons {
    min-width: 20px !important;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    transition: opacity 0.3s ease-in-out;
}

/* Track */
*::-webkit-scrollbar-track {
    border-radius: 5px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background: rgb(163, 163, 163);
    border-radius: 10px;
}

.h- {
    &60 {
        height: 60%;
    }
}
