@import '../node_modules/@angular/material/theming';
@import '../node_modules/xterm/css/xterm.css';
@include mat-core();

/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette0: (
    50: #e0effa,
    100: #b3d7f3,
    200: #80bceb,
    300: #4da1e3,
    400: #268cdd,
    500: #0078d7,
    600: #0070d3,
    700: #0065cd,
    800: #005bc7,
    900: #0048be,
    A100: #e6eeff,
    A200: #b3cbff,
    A400: #80a8ff,
    A700: #6796ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$primary: mat-palette($md-mcgpalette0, 600);
$accent: mat-palette($md-mcgpalette0, 600);
$warn: mat-palette($mat-red);
$box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);

$cosgrid-app-theme: mat-light-theme($primary, $accent, $warn);

@include angular-material-theme($cosgrid-app-theme);
$custom-typography: mat-typography-config(
    $font-family: 'Gill Sans',
);

@include angular-material-typography($custom-typography);
